<template>
  <strong><p>Do you really want to remove!</p></strong>
</template>

<script>
export default {
  name: 'RemoveAccountForm',
};
</script>

<style lang="scss" scoped></style>
